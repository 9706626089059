import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { useParams } from "react-router-dom";
import { useGetAPI } from "../../../../context/api";
import QuizAnswerKeys from "../Tabs/AnswerKey/QuizAnswerKeys";

const ShareQuizContainer = ({ children }) => {
    const { quiz_id } = useParams();
    const { loading, error, data } = useGetAPI(`/api/fullquizzes/${quiz_id}/`);
    if( loading ) return <div>Loading...</div>;
    if( error ) return <div>Error: {error}</div>;
    if( !data ) return <div>No data</div>;
    return (
        <div className='w-full p-10 '>
            <div className='max-w-[1000px] m-auto'>
                <div className=''>
                    <div className='text-3xl pb-2 font-bold'>
                        {data.title}
                    </div>

                    <QuizAnswerKeys quiz={data} hideEdit={true}/>
                </div>
            </div>
        </div>
    );
};

export default ShareQuizContainer;
