import React, { useContext, useState } from 'react';
import tw, { styled } from 'twin.macro';
import ReactJson from 'react-json-view';
import { useKey } from 'react-use';

// const S = {
//     Container: styled.div`
//         user-select: none;
//         position: fixed;
//         //left: 0;
//         //right: 0;
//         top: 0;
//         bottom: 0;
//         width: 50vw;
//         text-align: left;
//         z-index: 100000000;
//         background: white;
//         transition: right 0.2s;
//         right: ${props => props.open ? '0' : '-50vw'};
//         overflow-y: auto;
//     `
// };

const DataView = ({ data, open }) => {
    return (
        <div style={{
            userSelect: ' none',
            position: 'fixed',
            //left: 0;
            //right: 0;
            top: 0,
            bottom: 0,
            width: '50vw',
            textAlign: 'left',
            zIndex: 100000000,
            background: 'white',
            transition: 'right 0.2s',
            right: open ? '0' : '-50vw',
            overflowY: 'auto'
        }}>
            <ReactJson
                name='State'
                src={data}
                collapsed={1}
            />
        </div>
    );
};

const DevTool = ({ data, open, children }) => {
    return (
        <React.Fragment>
            {open && <DataView
                data={data}
                name='State' />}
            {children}
        </React.Fragment>
    );
};

const DevToolContext = React.createContext();

export const DevToolProvider = (props) => {
    const [states, setStates] = useState({});
    const [open, setOpen] = useState(false);

    // Shift+S toggles state viewer
    const predicate = (event) => {
        // const value = event.key === 's' && event.shiftKey;
        // console.log(event, value);
        return event.key === 's' && event.ctrlKey;
    };
    useKey(predicate, () => setOpen((state) => !state), { event: 'keydown' });

    return (
        <DevToolContext.Provider value={setStates}>
            <DataView
                open={open}
                data={states}
            />
            {props.children}
        </DevToolContext.Provider>
    );
};

export function useDevTool(name, data) {
    // console.log('useDevTool', name, data);
    const setStates = useContext(DevToolContext);
    React.useEffect(() => {
        if (setStates) {
            // console.log('setting devtool state ', name, data);
            setStates((state) => ({ ...state, [name]: data }));
        }
    }, [name, data, setStates]);
    return () => {
        // console.log('Destroy useDevTool', name);
        if (setStates)
            setStates((state) => state.filter(key => key !== name));
    };
}

export default DevTool;
