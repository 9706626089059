import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Paper, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Grid, Stack } from "@mui/material";
import BitmovinPlayer from "../../../Components/Bitmovin";
import {createSourceConfig, hasSourceConfig} from "../../../Components/Bitmovin/BitmovinPlayer";
import VideoPlayer from "../VideoPlayer";
import {VideoSpecs} from "./VideoInfo/VideoInfoPlayer";



const VideoLesson = ({ video, updateVideo }) => {
    return (
        <Stack>
            <h1>Lesson</h1>
        </Stack>
    );
};


export default VideoLesson;
