import React, {useCallback} from 'react';
import tw, {css, styled} from 'twin.macro';
import {Card, CardContent, CardHeader, Checkbox, Radio, Typography} from "@mui/material";
import RichText from "../../../../../Components/RichText/RichText";
import {QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION} from "../../constants";
import {useQuizEditor} from "../../QuizEditorProvider";
import {v4 as uuidv4} from "uuid";

export const QuizQuestionView = ({question, index}) => {
    const {quiz, updateQuiz, addNewQuestion} = useQuizEditor();

    const handleAddNewQuestion = useCallback((question) => {
        console.log("adding new question:", question);
        addNewQuestion(question);
    }, [quiz, updateQuiz]);

    return (
        <Card variant='blank' sx={{}}>

            <CardHeader
                avatar={
                    <Typography variant={'h4'}>
                        {index + 1}.
                    </Typography>
                }
                title={
                    <Typography variant={'h4'} sx={{fontSize: 26, fontFamily: 'Helvetica'}}>
                        <RichText text={question.question_text}/>
                    </Typography>
                }
                action={
                    <div>
                        <button onClick={() => handleAddNewQuestion(question)}>
                            Add To Quiz
                        </button>
                    </div>
                }
            />

            <CardContent>
                {question.choices?.map((choice, index) => {
                    let decorator = null;
                    if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
                        decorator = (
                            <Checkbox checked={choice.correct}/>
                        )
                    } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
                        decorator = (
                            <Radio checked={choice.correct}/>
                        )
                    }
                    return (
                        <div key={choice.id} className={'flex flex-row items-center gap-4'}>
                            {/*
                            <Avatar
                                sx={{
                                    bgColor: 'transparent',
                                    // bgcolor: 'red'
                                }}
                            >
                                {String.fromCharCode(65 + index)}
                            </Avatar>
                            */}
                            {decorator}

                            <Typography
                                variant='h6'
                                sx={{
                                    // bgColor: 'transparent',
                                    // bgcolor: 'red'
                                }}
                            >
                                {String.fromCharCode(65 + index)})
                            </Typography>

                            {choice.content_image ?
                                <img src={choice.choice_text} className={'max-h-[160px] max-w-[200px] aspect-square'}/>
                                :
                                <Typography variant={'body1'} sx={{fontSize: 20, fontFamily: 'Helvetica'}}>
                                    <RichText text={choice.choice_text}/>
                                </Typography>}
                        </div>
                    );
                })}
                {/*
                <div key={question.id}>
                    <div className={'font-bold text-gray-600'}>{index + 1}. {question.question_text}</div>
                    <div className={'font-bold text-gray-600'}>Answer: {question.choices[question.answer].choice}</div>
                </div>
                */}
            </CardContent>

        </Card>
    );
};


export default QuizQuestionView;
