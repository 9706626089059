import React from 'react';
import {Button, FormControl, FormLabel, Grid, InputLabel, OutlinedInput, Stack, TextareaAutosize} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import InputText, {InputSwitch} from "../../Quizzes/QuizEditor/FormControls";
import ThumbnailImage from "../ThumbnailImage";
import {showError} from "../../Quizzes/ErrorMessage";
import {useGetAPI} from "../../../context/api";
import LessonInputField from "../forms/LessonInputField";
import QuizInputField from "../forms/QuizInputField";
import BitmovinPlayer from "../../../Components/Bitmovin";
import {createSourceConfig} from "../../../Components/Bitmovin/BitmovinPlayer";
import VideoDetails from "./VideoDetails";
import {addRow} from "./InfoTable";
import VolumeDropdown from "../../StudioContainer/VolumeDropdown";
import {useStudio} from "../../Context/StudioContext";
import VideoInfoPlayer from "./VideoInfo/VideoInfoPlayer";
import LinkedField from "../forms/LinkedField";
import UploadTarget from "../NewVideoDialog/UploadTarget";

function ResizableTextField({value, onChange, label = null}) {
    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-description">{label}</InputLabel>
            <OutlinedInput
                multiline
                id="outlined-adornment-description"
                value={value}
                onChange={(ev) => onChange(ev.target.value)}
                label={label}
                inputComponent={TextareaAutosize}
                inputProps={{
                    minRows: 1,  // Initial minimum rows
                    maxRows: 6,  // Maximum rows it can grow to before scrolling
                    style: {resize: 'none'}  // Optional: Prevents user resizing
                }}
            />
        </FormControl>
    );
}


const VideoInfo = ({video, lesson, quiz, buttons, updateVideo, errors}) => {
    // const { selectedVolume, setSelectedVolume } = useStudio();
    // const [selectedVolume, setSelectedVolume] = React.useState();
    const linkComponent = (props) => {
        return (
            <div {...props}>
                hello
            </div>);
    }
    const updateQuiz = (quiz_id) => {
        console.log('updateQuiz', quiz_id);
        updateVideo({quiz_id});
    }
    const setSelectedVolume = (volume_id) => {
        console.log('setSelectedVolume', volume_id);
        updateVideo({volume: volume_id, volume_id});
    }
    return (
        <Grid container rowSpacing={1} columnSpacing={1}>

            {/*
            <Grid item xs={12}>
                {video.url_thumbnail && <img
                    src={video.url_thumbnail}
                    alt="video thumbnail"
                    className={'h-[200px]'}
                />}
                <CardMedia
                    component="img"
                    // height="40"
                    image={video.url_thumbnail}
                    alt="video thumbnail"
                />
            </Grid>
                */}
            <Grid item xs={8}>
                <VideoInfoPlayer video={video}/>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={2}>

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">Title</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={video.title}
                            onChange={(ev) => updateVideo({title: ev.target.value})}
                            // onChange={handleChange('amount')}
                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Title"
                        />
                    </FormControl>
                    {showError(errors.title, errors.title)}



                    {/*<ThumbnailImage video={video} updateVideo={updateVideo}/>*/}

                    {/*
                    <FormControl fullWidth variant="outlined">
                        <InputLabel shrink htmlFor="outlined-adornment-image">{'thumbnail'}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-image"
                            type="text"
                            value=""
                            // className={'bg-yellow-300 flex flex-col justify-items-stretch items-stretch m-0 p-0 flex-grow w-full'}
                            startAdornment={
                                <div style={{
                                    background: '',
                                    // margin: '20px 30px',
                                    // padding:'20px',
                                    flexGrow: 1
                                }}>
                                    <ThumbnailImage
                                        video={video}
                                        updateVideo={updateVideo
                                    }/>
                                </div>
                            }
                            label={'thumbnail'}
                            readOnly
                            notched
                        />
                    </FormControl>
                    */}

                    <ResizableTextField
                        label="Description"
                        value={video.description}
                        onChange={value => updateVideo({description: value})}
                    />

                    <FormControl fullWidth>
                        <div className='h-auto'>
                            <VolumeDropdown
                                className='w-full'
                                selectedVolume={video.volume_id}
                                onChangeVolume={setSelectedVolume}
                                hideAll={true}
                            />
                        </div>
                    </FormControl>

                    <LessonInputField lessonId={video.lesson_id} updateVideo={updateVideo}/>

                    <QuizInputField quizId={video.quiz_id} updateQuiz={updateQuiz}/>

                    {buttons}

                    {/*<LinkedField objectId={null} title={"Object Link"} />*/}


                    {/*
                                                        <img src={video.url_thumbnail} alt={'thumbnail'}
                                         style={{width: '100%', height: 'auto'}}/>

                                                    <div className={'w-full bg-red-300'} style={{ background:'red', width: '100%', height: 'auto' }}>

                                </div>


                    <InputText id='copyright_year' name='copyright_year' label="Copyright Year" value={video.copyright_year} onChange={(ev) => updateVideo({ copyright_year: ev.target.value })} />
                    {showError(errors.copyright_year, errors.copyright_year)}
                    */}

                    {/*
                    <InputSwitch id='hidden' name='hidden' label="Hidden" value={video.hidden} onChange={() => updateVideo({ hidden: !video.hidden })} />
                    <InputSwitch id='new' name='new' label="New" value={video.new} onChange={() => updateVideo({ new: !video.new })} />
                    <InputSwitch id='coming_soon' name='coming_soon' label="Coming Soon" value={video.coming_soon} onChange={() => updateVideo({ coming_soon: !video.coming_soon })} />
                    <hr />
                    */}


                    {/*                    <InputText
                        id='lesson'
                        name='lesson'
                        label="Lesson"
                        value={lesson?.title ?? ''}
                        readOnly={true}
                        // InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: linkComponent
                        }}
                        inputProps={{
                            inputComponent: linkComponent,
                            url: `/studio/lessons/${lesson?.id}/`
                        }}
                    />
                    <InputText
                        id='quiz'
                        name='quiz'
                        label="Quiz"
                        value={quiz?.title ?? ''}
                        readOnly={true}
                    />*/}
                    {/*
                    <FormLabel component="legend">Thumbnail</FormLabel>
                    <div className={'mb-4 flex '}>
                        <ThumbnailImage
                            video={video}
                            updateVideo={updateVideo}
                            containerClassName={video.url_thumbnail ? 'px-[0px] py-[0px] overflow-hidden' : ''}
                            className={' w-full'}
                        />

                    </div>
                    */}
                    {/*
                    <hr />

                    <FormLabel component="legend">Video</FormLabel>
                    <hr />
                    <hr />
                    <FormLabel component="legend">Video</FormLabel>

*/}

                    {/*
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
                    <OutlinedInput
                        multiline
                        id="outlined-adornment-amount"
                        value={video.description}
                        rows={4}
                        // onChange={handleChange('amount')}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Description"
                    />
                </FormControl>
                */}

                    {/*
                    <InputText id='slug' name='slug' label="Slug" value={video.slug} />
                    <InputText id='notes' name='notes' label="Notes" value={video.notes} multiline={true} minRows={2} />
                    */}
                </Stack>
            </Grid>


        </Grid>
    );
};


export default VideoInfo;
