import React, {useMemo, useState} from 'react';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableContainer,
    Grid,
    Stack
} from "@mui/material";
import BitmovinPlayer from "../../../Components/Bitmovin";
import {createSourceConfig, hasSourceConfig} from "../../../Components/Bitmovin/BitmovinPlayer";
import VideoPlayer from "../VideoPlayer";
import {VideoSpecs} from "./VideoInfo/VideoInfoPlayer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VideoDetails from "./VideoDetails";
import TabsContainer from "../../Editor/Tabs";


function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        className: 'h-full bg-green-200 whitespace-nowrap',
        style: {whiteSpace: 'nowrap'}
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    if (value !== index) {
        return null;
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={'flex flex-grow w-full bg-red flex-col h-full'}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, background: '', minHeight: '100%', position: 'relative'}}>
                    <div className={'inset-4- flex items-stretch justify-stretch'}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}


const VideoSettings = ({video, lesson, quiz, updateVideo}) => {
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        console.log(newValue);
        setTabValue(newValue);
    }

    const tabs = useMemo(() => {
        return [
//         {
//             id: 'general',
//             label: 'General',
//             component: (
//                 <VideoInfo
//                     video={video}
//                     lesson={lesson}
//                     quiz={quiz}
//                     updateVideo={updateVideo}
//                     errors={formErrors}
//                     buttons={editorButtons}
//                 />
//             )
//         },
//         {
//             id: 'video',
//             label: 'Video',
//             component: <VideoPanel video={video} updateVideo={updateVideo}/>
//
//         },
//         {
//             id: 'thumbnail',
//             label: 'Thumbnail',
//             component: <ThumbnailPanel video={video} updateVideo={updateVideo}/>
//
//         },
//         {
//             id: 'lyrics',
//             label: 'Lyrics',
//             component: <LyricsPanel video={video} updateVideo={updateVideo}/>
//         },
//         {
//             id: 'source_videos',
//             label: 'Video Files',
//             component: (
//                 <SourceVideos
//                     onSelectUpload={handleSelectUpload}
//                     video={video}
//                     updateVideo={updateVideo}
//                     uploadId={uploadId}
//                 />
//             )
//         },
//         {
//             id: 'healthcheck',
//             label: 'Health Check',
//             component: <VideoHealthCheck video={video}/>
//         },
//         {
//             id: 'storage',
//             label: 'Storage',
//             component: (
//                 <VideoStorage
//                     onSelectUpload={handleSelectUpload}
//                     video={video}
//                     updateVideo={updateVideo}
//                     uploadId={uploadId}
//                 />
//             )
//         },
//         {
//             id: 'files',
//             label: 'Files',
//             component: <FilesPanel onSelectUpload={handleSelectUpload} video={video} updateVideo={updateVideo}
//                                    uploadId={uploadId}/>
//         },
//         {
//             id: 'streams',
//             label: 'Streams',
//             component: <VideoStreams video={video} updateVideo={updateVideo}/>
//         },
//         {
//             id: 'lesson',
//             label: 'Lesson',
//             component: <VideoLesson video={video} updateVideo={updateVideo}/>
//         },
//         {
//             id: 'settings',
//             label: 'Settings',
//             component: <VideoSettings video={video} updateVideo={updateVideo}/>
//         },
            {
                id: 'details',
                label: 'Details',
                component: <VideoDetails
                    video={video}
                    lesson={lesson}
                    quiz={quiz}
                    updateVideo={updateVideo}
                />
            }
        ];
    }, [lesson, quiz, updateVideo, video]);

    return (
        <div className={'flex flex-row h-full flex-grow min-h-[400px]'}>

                <Tabs
                    orientation="vertical"
                    // variant="scrollable"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={'w-[300px] flex flex-col h-full bg-[red] min-h-[400px]'}
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        height: '100%',
                    }}
                >
                    {tabs?.map((t, index) => (
                        <Tab key={t.id} label={t.label} value={t.id} {...a11yProps(index)} />
                    ))}

                </Tabs>

            {tabs.map((t, index) => (
                <TabPanel key={t.id} value={tabValue} index={index} selected={index}>
                    {t.component}
                </TabPanel>
            ))}


        </div>
    );
};


export default VideoSettings;
