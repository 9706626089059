import React, {useMemo, useState} from 'react';
import { useGetAPI } from "../../context/api";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Fab, Modal, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../Home/PageContainer";
import AddIcon from "@material-ui/icons/Add";
import NewQuizDialog from "./NewQuizDialog";
import useSWR from "swr";
import { ContentCopyOutlined, Delete, Edit } from "@material-ui/icons";
import { useDevTool } from "../../context/DevTool";
import HomeContainer, { useBreadcrumb } from "../HomeContainer";
import QuizTable from "./QuizTable";
import {SearchField} from "../Videos/SearchField";
// const rows = [
//     { id: 1, col1: 'Hello', col2: 'World' },
//     { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
//     { id: 3, col1: 'MUI', col2: 'is Amazing' },
// ];


const Item = styled(Paper)(({ theme }) => (
    {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
));


const QuizzesContainer = (props) => {
    // download quizzes
    const navigate = useNavigate();
    const { loading, error, data } = useSWR('/api/quizzes/');
    const [search, setSearch] = useState('');

    // console.log(error, data);
    const rows = useMemo(() => {
        let items = data?.data ?? [];
        // handle search
        if (search) {
            items = items.filter(quiz => quiz.title.toLowerCase().includes(search.toLowerCase()));
        }
        return items.map((quiz, index) => {
            return {
                index: index,
                quiz: quiz,

                id: quiz.id,
                title: quiz.title,
                description: quiz.description,
                created: quiz.created,
                updated: quiz.updated,
                quiz_type: quiz.quiz_type,
                is_live: quiz.is_live,
                volume_id: quiz.volume_id,
                volume_title: quiz.volume_title,
                healthcheck_valid: quiz.healthcheck_valid,
                healthcheck_checks: quiz.healthcheck_checks,

                question_count: quiz.questions?.length,

            };
        });
    }, [data, search])

    const onQuizClick = (quiz) => {
        navigate(`/studio/quiz/${quiz.id}`);
    }

    const [openNewQuiz, setOpenNewQuiz] = React.useState(false);
    const handleCreateQuiz = () => {
        setOpenNewQuiz(true);
    };

    const addButton = (
        <Fab key={'add-new-quiz'} color="primary" aria-label="add" onClick={handleCreateQuiz}>
            <AddIcon />
        </Fab>
    );
    useDevTool('Quizzes', { rows, quizzes: data?.data });

    // useBreadcrumb('Quizzes', '/quizzes');
    const handleOnSearch = (value) => {
        console.log('search', value);
        setSearch(value);
    }

    return (
        <HomeContainer
            menu_items={null}
            title='Quizzes'
            actions={[
                <SearchField value={search} onSearch={handleOnSearch} />,
                addButton
            ]}
            initialBreadcrumbs={[
                { title: 'Home', href: '/', },
                { title: 'Studio', href: '/studio/' },
            ]}
        >

            <Card className={'flex-grow h-full-'}>
                {rows && <QuizTable quizzes={rows} onClickQuiz={onQuizClick} />}
            </Card>

            <NewQuizDialog open={openNewQuiz} onClose={() => setOpenNewQuiz(false)} />

        </HomeContainer>
    );
};


export default QuizzesContainer;
