import React from 'react';
import RenderQuiz from "../../../Worksheet/QuizPage";
import { useGetAPI } from "../../../../../context/api";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, List, ListItemText, MenuItem, MenuList, Paper, Stack, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { QUIZ_LEVELS } from "../../constants";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ContentCut } from "@material-ui/icons";


const WorksheetLevel = ({ quiz, level, answer_key }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Quiz Level {level} - {QUIZ_LEVELS[level]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <RenderQuiz
                                quiz={quiz}
                                level={level}
                                answer_key={answer_key}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
const QuizWorksheet = ({ quiz }) => {
    // const { quizId } = props.match.params;
    // const { loading, error, data } = useGetAPI(`/api/fullquizzes/${quizId}/`);
    const levels = [
        {
            level: 0,
            name: "Silver",
            answer_key: false,
        },
        {
            level: 1,
            name: "Gold",
            answer_key: false,
        },
        {
            level: 2,
            name: "Platinum",
            answer_key: false,
        },
        {
            level: 0,
            name: "Silver Answer Key",
            answer_key: true,
        },
        {
            level: 1,
            name: "Gold Answer Key",
            answer_key: true,
        },
        {
            level: 2,
            name: "Answer Key",
            answer_key: true,
        },
    ]

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const selected = levels[selectedIndex];
    const link = '/quiz/worksheet?quizId=' + quiz.id + '&level=' + selected?.level + '&answerKey=' + selected?.answer_key;
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Stack spacing={2}>
                    <Paper sx={{ width: 320, maxWidth: '100%' }} elevation={0}>
                        <Stack spacing={2} sx={{ width: '100%' }}>

                            <MenuList>
                                {levels.map((level, index) => {
                                    return (
                                        <MenuItem onClick={() => setSelectedIndex(index)}>
                                            <ListItemText>
                                                {level.name}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}

                                {/*
                        <MenuItem>
                            <ListItemIcon>
                                <ContentCut fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Cut
                            </ListItemText>
                            <Typography variant="body2" color="text.secondary">
                                ⌘X
                            </Typography>
                        </MenuItem>
                        */}
                            </MenuList>

                        </Stack>
                    </Paper>
                    <Button ssx={{ margin: '20px' }} fluid variant={'outlined'}>
                        Print
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={10}>
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    click here to print: {link}
                </a>
                <Paper variant="outlined">
                    <RenderQuiz
                        quiz={quiz}
                        level={selected?.level}
                        answer_key={selected?.answer_key}
                    />
                </Paper>
            </Grid>
        </Grid>
    )

    /*
        <RenderQuiz
            quiz={props.quiz}
            level={1}
            // answer_key={true}
        />

        <RenderQuiz
            quiz={props.quiz}
            level={2}
            // answer_key={true}
        />

        <RenderQuiz
            quiz={props.quiz}
            level={0}
            answer_key={true}
        />

        <RenderQuiz
            quiz={props.quiz}
            level={1}
            answer_key={true}
        />

        <RenderQuiz
            quiz={props.quiz}
            level={2}
            answer_key={true}
        />

    </div>
    */
};

export default QuizWorksheet;
