import React from 'react';
import {Button, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import {getLevelName, QUIZ_BACKGROUNDS} from "../../constants";
import {useNavigate} from "react-router-dom";
import {AnswerKeyQuestion} from "./AnswerKeyQuestion";


const QuizAnswerKeys = ({ quiz, hideEdit }) => {
    const navigate = useNavigate();
    // group questions by level
    const questionsByLevel = quiz.questions.reduce((acc, question) => {
        if (!acc[question.level]) {
            acc[question.level] = [];
        }
        acc[question.level].push(question);
        return acc;
    }, {});

    // sort the questions by question_index
    Object.keys(questionsByLevel).forEach((level) => {
        questionsByLevel[level].sort((a, b) => {
            return a.question_index - b.question_index;
        });
    });

    // get a list of levels
    const levels = Object.keys(questionsByLevel).map((level) => {
        return {
            level: parseInt(level),
            questions: questionsByLevel[level]
        };
    });
    // console.log("levels", levels);
    return (
        <Stack spacing={2} className={'-bg-blue-200 w-full'}>
            {levels.map((level, index) => {
                return (
                    <Card key={level.level} sx={{ width: '100%' }}>
                        <CardHeader
                            // title={`${getLevelName(level.level)} Level `}
                            title={
                                <Typography variant={'h5'}>
                                    {getLevelName(level.level)} Level
                                </Typography>
                            }
                            sx={{
                                backgroundColor: QUIZ_BACKGROUNDS[level.level],
                            }}
                            action={
                                <Stack direction={'row'} spacing={2}>

                                    {/*
                                        <Typography variant={'h4'}>
                                            {getLevelName(level.level)} Level
                                        </Typography>
                                        */}
                                    {(
                                        !hideEdit
                                    ) && <>
                                        <Button variant={'outlined'} color={'primary'}>
                                            Preview
                                        </Button>
                                        <Button variant={'outlined'} color={'primary'}>
                                            Edit
                                        </Button>
                                    </>
                                    }
                                </Stack>

                            }
                            // avatar={
                            //     <Avatar sx={{ bgcolor: 'black' }}>
                            //         {index + 1}
                            //     </Avatar>
                            // }
                        />
                        <CardContent>
                            {/*
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Level {index + 1}
                                </Typography>
*/}
                            {/*
                            <Typography variant="h5" component="div">
                                {Q}
                            </Typography>
                            */}
                            <Stack spacing={2}>
                                {level.questions.map((question, index) => {
                                    return (
                                        <div style={{ position: 'relative' }}>
                                            {/*  Float a button on the upper right
                                                */}
                                            {/*     ((!hideEdit) && <Fab
                                                onClick={() => navigate(`/quiz/${quiz.id}/question/${question.id}`)}
                                                size='small'
                                                color="info"
                                                aria-label="edit"
                                                sx={{ position: 'absolute', top: -10, right: -10 }}
                                            >
                                                <Edit />
                                            </Fab>)
*/}

                                            <AnswerKeyQuestion
                                                key={question.id}
                                                question={question}
                                                index={index}
                                                hideEdit={hideEdit}
                                            />

                                            {(
                                                index < (
                                                    level.questions.length - 1
                                                )
                                            ) && <hr />}
                                        </div>
                                    )
                                })}
                            </Stack>

                        </CardContent>
                    </Card>
                );
            })}
            {/*
            <Stack spacing={2}>
                {quiz.questions.map((question, index) => {
                    return (
                        <div key={index}>
                            <div className={'font-bold text-gray-600'}>{question.question_index}. {question.question}</div>
                            <div className={'font-bold text-gray-600'}>Answer: {question.choices[question.answer].choice}</div>
                        </div>
                    );
                })}
            </Stack>
            */}

        </Stack>
    );
};


export default QuizAnswerKeys;
