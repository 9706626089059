import React, {useCallback} from 'react';
import Panel from "../../Components/Dashboard/Panel";
import apiClient from "../../context/APIClient";
import {useDevTool} from "../../context/DevTool";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import useSWR from "swr";
import {Refresh} from "@material-ui/icons";


export const StatusRow = ({text, status, errors}) => {
    const [showErrors, setShowErrors] = React.useState(true);

    const toggleShowErrors = useCallback(() => {
        console.log('toggleShowErrors');
        setShowErrors(!showErrors)
    }, [showErrors]);

    return (
        <>
            <tr className="px-4 py-2 cursor-pointer border-b last:border-none" onClick={toggleShowErrors}>
                <td className="px-4 py-2 w-full" onClick={toggleShowErrors}>
                    {text}
                </td>
                <td className="px-4 py-2 ">
                    {status ?
                        <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">PASS</span>
                        :
                        <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                    }
                </td>
            </tr>
            {showErrors && errors?.length > 0 && <tr className="px-4 py-2 border-b last:border-none">
                <td className="px-4 py-2 w-full" colSpan={2}>
                    <div className="bg-red-200 text-red-700 px-2 py-1 rounded">
                        {errors.map((error, index) => {
                            return <div key={index} dangerouslySetInnerHTML={{__html: error}}/>
                        })}
                    </div>
                </td>
            </tr>}
        </>
    );
};

export const addStatusRow = (text, status, errors = null) => {
    return (
        <StatusRow key={text} text={text} status={status} errors={errors}/>
    );
}

const HealthCheckPanel = ({ checks, isValid, performChecks }) => {

    useDevTool('checks', checks);

    // checking
    if (isValid === null) {
        return (
            <Typography variant={'h4'} className={'w-full'}>
                <div className="w-full m-auto bg-gray-200 text-gray-700 px-2 py-1 rounded">Checking...</div>
            </Typography>
        );
    }

    return (
        <Grid container rowSpacing={4} spacing={1}>
            <Grid item xs={12}>

                <Stack spacing={2}>

                    <Stack spacing={2} direction={'row'}>
                        <Button
                            onClick={() => performChecks(true)}
                            variant='contained'
                            color='primary'
                            startIcon={<Refresh/>}
                        >
                            Check
                        </Button>
                    </Stack>

                    <Typography variant={'h4'}>
                        {isValid ?
                            <div className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">Health Check: PASS</div> :
                            <div className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">Health Check: FAIL</div>
                        }
                    </Typography>

                    <Panel title={'Health Checks'}>
                        <Stack spacing={0}>
                            <table className="table-auto w-full">
                                <tbody>
                                {checks.map(check => {
                                    console.log(check);
                                    return addStatusRow(check.title, check.valid, check.errors)
                                })}
                                </tbody>
                            </table>
                        </Stack>
                    </Panel>
                </Stack>
            </Grid>

        </Grid>

    );
};


export default HealthCheckPanel;
