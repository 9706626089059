// quiz question types
export const QUESTION_TYPE_MULTIPLE_CHOICE = 0;
export const QUESTION_TYPE_MULTIPLE_SELECTION = 1;
export const QUESTION_TYPE_TRUE_FALSE = 2;
export const QUESTION_TYPE_FREE_ENTRY = 3;
export const QUESTION_TYPE_LYRICS_DROPDOWN = 4;
export const QUESTION_TYPE_FILL_IN_THE_BLANK = 5
export const QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS = 6

export const QUESTION_TYPE_CHOICES = [
    [QUESTION_TYPE_MULTIPLE_CHOICE, 'Multiple choice'],
    [QUESTION_TYPE_MULTIPLE_SELECTION, 'Multiple selection'],
    [QUESTION_TYPE_FREE_ENTRY, 'Free entry'],
    [QUESTION_TYPE_LYRICS_DROPDOWN, 'Lyrics dropdown'],
    [QUESTION_TYPE_FILL_IN_THE_BLANK, 'Fill in the blank'],
    [QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS, 'Fill in multiple blanks'],
    [QUESTION_TYPE_TRUE_FALSE, 'True or False'],
];

export const QUIZ_TYPE_STANDARD = 'standard';  // 10 questions per level, 5 question unlock
export const QUIZ_TYPE_MINI = 'mini';  // 5 questions per level
export const QUIZ_TYPE_RESEARCH = 'research';
export const QUIZ_TYPE_LYRICS = 'lyrics';

export const QUIZ_TYPE_CHOICES = [
    [QUIZ_TYPE_STANDARD, 'Standard'],
    [QUIZ_TYPE_MINI, 'Mini'],
    [QUIZ_TYPE_RESEARCH, 'Research'],
    [QUIZ_TYPE_LYRICS, 'Lyrics'],
];

// level names and colors
export const QUIZ_LEVELS = ['Silver', 'Gold', 'Platinum'];
export const QUIZ_COLORS = ['text-gray-600', 'text-yellow-500', 'text-blue-500'];
export const QUIZ_BACKGROUNDS = ['silver', 'gold', '#a0b2c6'];
export const QUIZ_FOREGROUNDS = ['black', 'black', 'white'];

export const getLevelName = level => QUIZ_LEVELS[level % QUIZ_LEVELS.length];
export const getLevelColor = level => QUIZ_COLORS[level % QUIZ_COLORS.length];
export const getLevelBackground = level => QUIZ_BACKGROUNDS[level % QUIZ_BACKGROUNDS.length];

// choices for quiz level
export const QUIZ_LEVEL_CHOICES = [
    [0, 'Silver'],
    [1, 'Gold'],
    [2, 'Platinum'],
]
