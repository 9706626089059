import React, {useRef} from "react";
import {
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    OutlinedInput,
    Paper,
    Radio
} from "@mui/material";
import * as PropTypes from "prop-types";
import {
    QUESTION_TYPE_FREE_ENTRY,
    QUESTION_TYPE_MULTIPLE_CHOICE,
    QUESTION_TYPE_MULTIPLE_SELECTION
} from "../../constants";
import {useQuizEditor} from "../../QuizEditorProvider";
import ImageIcon from "@mui/icons-material/Image";
import VolumeUpSharpIcon from "@mui/icons-material/VolumeUpSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import QuestionChoiceImage from "../../QuestionChoiceImage";
import ConfirmDialog from "../../ConfirmDialog";
import ChoiceSettingsDialog from "./ChoiceSettingsDialog";
import RichText from "../../../../../Components/RichText/RichText";
import {MathEditor} from "../QuestionTextEditor/vendors/MathLive/MathEditor";
import Button from "@material-ui/core/Button";
import {convertToMath} from "../../../Generators/MathGenerator";
import Loading from "../../../../../Components/Loading/Loading";
import {LoadingButton} from "@mui/lab";
import {NumbersRounded} from "@mui/icons-material";
import {ArrowLeft, ArrowRight, ChevronLeft} from "@material-ui/icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// const CustomCard = React.forwardRef(({ children, ...props }, ref) => {
export const QuestionChoice = React.forwardRef((
    {
        index,
        question,
        choice,
        updateChoice,
        deleteChoice,
        handleChangeChoiceValue,
        addChoiceBlock,
        children
    }, ref) => {
    const letter = String.fromCharCode(65 + index);
    const {modifyQuestionChoice} = useQuizEditor();
    const [isEditing, setIsEditing] = React.useState(false);

    const actionList = [
        {
            id: 'image',
            icon: <ImageIcon/>,
            name: 'Image',
        },
        /*
        {
            id: 'play-audio',
            icon: <TuneIcon />,
            name: 'Audio',
        },
        */
        {
            id: 'audio-settings',
            icon: <VolumeUpSharpIcon/>,
            name: 'Tune',
            // color: 'success',
        },
        {
            id: 'settings',
            icon: <SettingsIcon/>,
            name: 'Settings',
        },
        {
            id: 'delete',
            icon: <DeleteIcon/>,
            name: 'Delete',
        }
    ];
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: question.id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    // console.log(choice);
    let image = null;
    if (choice.content_image) {
        image = (
            <img
                key={choice.id}
                src={choice.choice_text}
                height={'200px'}
                width={'auto'}
                // height={'200px'}
                className={'max-h-[100px] max-w-[100px] bg-orange-400- m-auto'}
            />
        )
    }

    const [showText, setShowText] = React.useState(false);

    const handleChangeChoiceCorrect = (ev, choice) => {
        console.log('handleChangeChoiceCorrect', ev.target.checked, ev.target.value);
        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
            // alert(ev.target.checked ? "yes" : 'no');
            // clear all the choices
            modifyQuestionChoice({...choice, correct: ev.target.checked})
            // question.choices.forEach((c) => {
            //     c.correct = false;
            // });
            // choice.correct = !choice.correct;
            // choice.correct = ev.target.checked;
        } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
            // toggle the selection
            console.log('handleChangeChoiceCorrect', choice.id, ev.target.checked);
            modifyQuestionChoice({id: choice.id, correct: ev.target.checked})
            // modifyQuestionChoice(choice.id, { correct: ev.target.checked })
            // choice.correct = !choice.correct;
            // modifyQuestionChoice({ id: choice.id, correct: ev.target.checked })
        }
    }
    let contents = null;
    if (choice.content_image && !showText) {
        contents = (
            <QuestionChoiceImage
                choice={choice}
                question={question}
                quiz_id={question.quiz_id}
                updateChoice={updateChoice}
            />
        );
        /*
        contents = (<div className={"bg-green-100- h-full min-w-[300px]"}>
            {image}
        </div>);
        */
    } else if (choice.content_math && !showText) {
        if (isEditing) {
            contents = (
                <FormControl fullWidth>
                    <MathEditor
                        value={choice.choice_text}
                        onChange={(value) => updateChoice?.({...choice, choice_text: value})}
                        containerStyle={{border: '4px solid red'}}
                        className={'block'}
                    />
                </FormControl>
            );

        } else {
            contents = (
                <div className={"bg-green-100 h-full min-w-[300px]"}
                     onClick={() => setIsEditing(true)}
                >
                    <RichText
                        className={'text-2xl min-h-[32px] w-full '}
                        text={choice.choice_text}
                        onClick={() => setIsEditing(true)}
                    />
                </div>
            );
        }
    } else {
        if (isEditing) {
            contents = (
                <FormControl fullWidth>
                    <InputLabel id="choice-text-label">Text</InputLabel>
                    <OutlinedInput
                        labelId="choice-text-label"
                        id="question-type"
                        label="Text"
                        defaultValue={choice.choice_text}
                        autoFocus={true}
                        onBlur={(ev) => {
                            const text = ev.target.value;
                            updateChoice?.({...choice, choice_text: text});
                            setIsEditing(false);
                        }}
                        // value={choice.choice_text}
                        // onChange={(ev) => handleChangeChoiceValue(ev, choice.id)}
                        // onChange={createHandleChangeValue('choice_text')}
                    />
                </FormControl>
            );
            contents = (
                <textarea
                    // className={'w-full'}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                    // labelId="choice-text-label"
                    // label="Text"
                    rows={1}
                    id="question-type"
                    defaultValue={choice.choice_text}
                    autoFocus={true}
                    onBlur={(ev) => {
                        function detectChoiceBlock(text) {
                            const lines = text.trim().split("\n");
                            const pattern = /^[A-D]\.\s(.+)/;
                            let extractedLines = [];

                            for (let line of lines) {
                                let match = line.match(pattern);
                                if (!match) {
                                    return false; // If any line doesn't match, return false
                                }
                                extractedLines.push(match[1]); // Extract the text after "A.", "B.", etc.
                            }

                            // Ensure there are between 1 and 4 lines
                            return extractedLines.length >= 1 && extractedLines.length <= 4 ? extractedLines : false;
                        }

                        function detectChoiceInlineBlock(text) {
                            const pattern = /\b([A-D])\.\s+([^A-D]+?)(?=\s+[A-D]\.|$)/g;
                            let matches = [];
                            let match;

                            while ((match = pattern.exec(text)) !== null) {
                                matches.push(match[2].trim()); // Extract the text after "A.", "B.", etc.
                            }

                            // Ensure there are between 1 and 4 valid matches
                            return matches.length >= 1 && matches.length <= 4 ? matches : false;
                        }

                        const text = ev.target.value;

                        const choiceBlock = detectChoiceBlock(text) || detectChoiceInlineBlock(text);
                        console.log("Choice block", choiceBlock);
                        if (choiceBlock) {
                            // updateChoice?.({...choice, choice_text: text});
                            addChoiceBlock?.(choiceBlock);
                        } else {
                            updateChoice?.({...choice, choice_text: text});
                        }
                        setIsEditing(false);
                    }}
                    // value={choice.choice_text}
                    // onChange={(ev) => handleChangeChoiceValue(ev, choice.id)}
                    // onChange={createHandleChangeValue('choice_text')}
                />
            );
        } else {
            contents = (
                <RichText
                    className={'text-2xl min-h-[32px] w-full '}
                    text={choice.choice_text}
                    onClick={() => setIsEditing(true)}
                />
            );
        }
    }

    // create the input control
    let control = null;
    if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
        control = (
            <Checkbox
                inputProps={{'aria-label': 'Checkbox demo'}}
                // value={choice.id}
                // defaultChecked={!!choice?.correct}
                checked={choice.correct ?? false}
                onChange={(ev) => handleChangeChoiceCorrect(ev, choice)}
            />
        );
    } else if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
        // <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} defaultChecked={choice.correct} />
        control = (
            <Radio
                // checked={choice.correct}
                // onChange={handleChange}
                // value={choice.id
                // defaultChecked={choice?.correct ? true : false}
                checked={!!choice?.correct}
                value={choice.id}
                name={`correct-${choice.id}`}
                inputProps={{'aria-label': 'A'}}
                onChange={(ev) => handleChangeChoiceCorrect(ev, choice)}
            />
        );
    } else if (question.type === QUESTION_TYPE_FREE_ENTRY) {
        control = null;
        // (
        //     <Input inputProps={{ 'aria-label': 'Checkbox demo' }} value={choice.choice_text} />
        // );
    }
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [choiceSettingsOpen, setChoiceSettingsOpen] = React.useState(false);

    // actions
    const handleSetImage = (choiceId) => {
        // toggle the 'content_image' flag on the choice
        setShowText(!showText);
        // updateChoice({ ...choice, content_image: !choice.content_image });
    }

    const handleDeleteChoice = (choiceId) => {
        //
        deleteChoice(choice.id);
    }

    const audioElement = useRef();

    function handlePlayAudio(choice) {
        if (!choice.audio) {
            return;
        }

        if (audioElement.current) {
            // audioElement.current.pause();
            audioElement.current.play();
            audioElement.current.currentTime = 0;
        }

        // audioElement.current = new Audio(choice.audio);

    }

    const handleClickAction = (choice, action) => {
        if (action.id === 'toggle-image') {
            handleSetImage(choice.id);
        } else if (action.id === 'play-audio') {
            handlePlayAudio(choice);
        } else if (action.id === 'audio-settings') {
            // handleAudioSettings(choice);
        } else if (action.id === 'settings') {
            setChoiceSettingsOpen(true);
        } else if (action.id === 'delete') {
            setConfirmDeleteOpen(true);
        }
    };

    const getActionColor = (choice, action_id) => {
        if (action_id === 'play-audio') {
            // return 'success';
        }
        if (action_id === 'audio-settings') {
            // return 'success';
        }
        if (action_id === 'settings') {
            // return 'info';
        }
        if (action_id === 'delete') {
            // return 'warning';
        }
        if (action_id === 'image') {
            // return choice.content_image ? 'success' : 'default';
        }
        // 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
        return 'default'
    }
    // return (<li ref={setNodeRef} style={style} {...attributes} {...listeners}>
    //     {choice.id}
    // </li>);
    const actions = [
        /*
        {
            id: 'image',
            icon: <ImageIcon />,
            name: 'Image',
        },
        {
            id: 'audio-settings',
            icon: <TuneIcon />,
            name: 'Tune',
            // color: 'success',
        },
        */
        {
            id: 'settings',
            icon: <SettingsIcon/>,
            name: 'Settings',
        },
        {
            id: 'delete',
            icon: <DeleteIcon/>,
            name: 'Delete',
        }
    ];

    // insert the button at the front of actions
    if (choice.content_image) {
        actions.unshift({
            id: 'toggle-image',
            icon: <ImageIcon/>,
            name: 'Image',
        });
    } else {
        actions.unshift({
            id: 'play-audio',
            icon: <VolumeUpSharpIcon/>,
            name: 'Audio',
        });
    }

    let is_math = false;
    let is_complex_math = false;
    // <math>4 + \frac{1}{3} * f^5</math>
    // if choice_text is wrapped in <math> tags, then is_math = true
    if (choice.choice_text) {
        is_math = choice.choice_text && choice.choice_text.startsWith('<math>') && choice.choice_text.endsWith('</math>');
        // if it has more than one <math> tag, then it is complex math
        is_complex_math = choice.choice_text && choice.choice_text.indexOf('<math>') !== choice.choice_text.lastIndexOf('<math>');
    }

    const [loading, setLoading] = React.useState(false);

    const handleToMath = (choice) => {
        // convert this to math
        if (choice.choice_text) {
            let is_math = choice.choice_text && choice.choice_text.startsWith('<math>') && choice.choice_text.endsWith('</math>');
            if (is_math) {
                // strip the <math> tags
                let value = choice.choice_text.replace('<math>', '').replace('</math>', '');
                updateChoice?.({...choice, choice_text: value});
            } else {
                let value = `<math>${choice.choice_text}</math>`;
                updateChoice?.({...choice, choice_text: value});
            }
        }
    }

    const handleToggleMath = () => {
        if (is_math) {
            // strip the <math> tags
            let value = choice.choice_text.replace('<math>', '').replace('</math>', '');
            updateChoice?.({...choice, choice_text: value});
        } else {
            if (choice.choice_text) {
                setLoading(true);
                convertToMath(choice.choice_text)
                    .then((value) => {
                        console.log('converted to math', value);
                        updateChoice?.({...choice, choice_text: `<math>${value}</math>`});
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
            }
        }
    }


    return (
        <div
            id={choice.id}
            ref={ref}
            style={{...style}}
            // className={'bg-red-100'}
            // ref={setNodeRef}
            // style={style}
            // {...attributes}
            // {...listeners}
        >
            <Grid item xs={12}>

                {/*<CircularProgress color="inherit" />*/}
                {/*<Loading loading={true} />*/}
                <Card variant={'outlined'}>
                    <CardContent className={'relative'}>
                        <div className={"flex flex-row items-center gap-1 justify-between -bg-yellow-400"}>

                            <div className={"left-side w-full flex flex-row  text-2xl -bg-red-100 gap-2 items-center"}>
                                <div className={"bg-green-100-"}>
                                    {control}
                                </div>
                                <div className={"text-2xl w-6 bg-red-100-"}>
                                    {letter}
                                </div>
                                {contents}
                                {/*
                                {image && <div className={"bg-green-100- h-full min-w-[300px]"}>
                                    {image}
                                </div>}
                                <div className={"bg-blue-100- w-full"}>
                                    {contents}
                                </div>
                                */}
                            </div>

                            <div className={"bg-green-200- flex flex-row align-center justify-center"}>

                                {choice.last_position && <Chip label="Last Position" variant="outlined"/>}

                                {(!choice.content_image) && <LoadingButton
                                    size={'large'}
                                    loading={loading}
                                    variant="outlined"
                                    onClick={handleToggleMath}
                                    color={is_math ? "warning" : "primary"}
                                    disabled={is_complex_math}
                                >
                                    {/*{is_math ? <ChevronLeft/> :<ChevronRightIcon />}*/}
                                    {/*<NumbersRounded />*/}
                                    <ChevronRightIcon/>
                                    {is_complex_math ? '...' : (is_math ? 'TEXT' : 'MATH')}
                                </LoadingButton>}

                                {actions.map((action, index) => {
                                    return (
                                        <IconButton
                                            size={'small'}
                                            disableFocusRipple={true}
                                            key={action.name}
                                            // color={action.color ?? 'default'}
                                            color={getActionColor(choice, action.id)}
                                            // aria-label={action.name}
                                            onClick={() => handleClickAction(choice, action)}
                                        >
                                            {action.icon}
                                        </IconButton>
                                    )
                                })}
                            </div>

                            <div className={'h-full'}>
                                {children}
                            </div>

                        </div>

                        <div className={' absolute text-right w-full'}>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <ChoiceSettingsDialog
                choice={choice}
                open={choiceSettingsOpen}
                setOpen={setChoiceSettingsOpen}
                // onConfirm={handleConfirmChoiceSettings}
                updateChoice={updateChoice}
                onDelete={handleDeleteChoice}
            />

            <ConfirmDialog
                title="Are you sure you want to delete this choice?"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={() => handleDeleteChoice(choice.id)}
            >
                <p>Are you sure you want to delete this choice?</p>
            </ConfirmDialog>

            <audio
                ref={audioElement}
                src={choice.audio}
                className='audio-player-node'
                autoPlay={false}
            />

        </div>
    );
});

QuestionChoice.propTypes = {
    control: PropTypes.any,
    letter: PropTypes.string,
    image: PropTypes.any,
    contents: PropTypes.any,
    actions: PropTypes.any,
    callbackfn: PropTypes.func
};
