import React, {useMemo, useState} from 'react';
import { Grid, Dialog, DialogContent, List, ListItem, ListItemButton, ListItemText, DialogTitle } from "@mui/material";
// import apiClient from '../../context/APIClient';
// import { useGetAPI } from '../../context/api';
import { useStudio } from '../Context/StudioContext';
import { VideoCard } from "../../Components";
import VideoTile from "./VideoTile";
import VolumeDropdown from "../StudioContainer/VolumeDropdown";

const AddVideoDialog = ({ open, onClose, volumeId, onSuccess }) => {
    const { lessons } = useStudio();
    // const { data: lessons } = useGetAPI(`/api/lessons/`);
    // const studio = useStudio();
    // const { mutateCourses, mutateLessons } = studio;
    const handleClose = () => {
        onClose();
    }
    const onSubmitLesson = (lessonId) => {
        console.log('on submit lesson: ', lessonId);
        onSuccess(lessonId);
        // if(!parentId) {
        //     console.log("No parent id");
        //     handleClose();
        //     return;
        // }
        //
        // const payload = { lessons: [...groupLessons, lessonId] }
        // apiClient.patch(`/api/admin/lesson_groups/${parentId}/`, payload)
        //     .then((response) => {
        //         console.log(response);
        //         // mutateLessons();
        //         // mutateCourses();
        //         handleClose();
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }
    const [selectedVolume, setSelectedVolume] = useState(volumeId);
    console.log('selectedVolume', selectedVolume);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle id="lessons-dialog">Add Video</DialogTitle>
            <DialogContent>
                <VolumeDropdown selectedVolume={selectedVolume} onChangeVolume={setSelectedVolume} />
                <VideoList lessons={lessons} volumeId={selectedVolume} onSubmitLesson={onSubmitLesson} />
            </DialogContent>
        </Dialog>
    );
};

const VideoGrid = ({ lessons, onSubmitLesson, volumeId }) => {
    const { videos } = useStudio();
    console.log('volume: ', volumeId);
    const filtered = useMemo( () => {
        if(!volumeId) return lessons;
        return lessons.filter(lesson => lesson.volume_id === volumeId);
    }, [lessons, volumeId]);
    console.log('videos', videos);
    console.log('filtered', filtered);
    return (
        <Grid container spacing={2}>
            {filtered?.map((lesson) => {
                const video = videos?.find(video => video.id === lesson.video);
                console.log('video', video);
                return (
                    <Grid key={lesson.id} item xs={12} sm={6} md={4} lg={3}>
                        <VideoTile video={video} onClick={() => onSubmitLesson(lesson.id)}/>
                        {/*
                            <VideoCard
                                lesson={lesson}
                                onClick={() => onSubmitLesson(lesson.id)}
                            />
                        */}
                    </Grid>
                )
            })}
        </Grid>
    );
};

const VideoList = ({ lessons, onSubmitLesson, volumeId }) => {
    const { videos } = useStudio();
    console.log('volume: ', volumeId);

    const filtered = useMemo( () => {
        if(!volumeId) return videos;
        return videos?.filter(lesson => lesson.volume_id === volumeId);
        // if(!volumeId) return lessons;
        // return lessons?.filter(lesson => lesson.volume_id === volumeId);
    }, [videos, volumeId]);

    console.log('filtered: ', filtered.length);

    return (
        <List>
            {/*
            <h1>{volumeId}</h1>
            <h1>{filtered.length}</h1>
            */}
            {filtered?.map((lesson) => {
                // const video = videos?.find(video => video.id === lesson.video);
                return (
                    <ListItem disablePadding key={lesson.id}>
                        <ListItemButton
                            // selected={lessonId === video.id}
                            onClick={() => onSubmitLesson(lesson.id)}
                        >
                            <VideoTile video={lesson} onClick={() => onSubmitLesson(lesson.id)}/>
                            {/*<ListItemText primary={`${lesson.title} (${lesson.volume_title})`} />*/}
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    );
};

export default AddVideoDialog