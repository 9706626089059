import React, {useEffect, useRef, useState} from 'react';
import {Box, TextField, Button, Typography, CircularProgress, Stack, Grid} from '@mui/material';
import tw, {css, styled} from 'twin.macro';
import apiClient from "../../context/APIClient";
import Message from "./Message";

const ChatWindow = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    // height: '80vh',
    // width: '90%',
    width: '100%',
    backgroundColor: '#ffffff',
    overflowY: 'scroll',
    border: '1px solid #ccc',
    padding: '1rem',
});

const Usage = styled(Typography)(({self}) => (
    {
        alignSelf: 'flex-start',
        maxWidth: '70%',
        padding: '0 1rem',
        margin: '0 0.5rem',
        fontSize: '0.8rem',
        color: '#666666',
    }
));

const ChatStream = ({model, systemPrompt}) => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [modelName, setModelName] = useState(model);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim()) {
            setMessages([...messages, {text: inputValue, self: true}]);
            setInputValue('');

            // post the message to the backend
            setLoading(true);
            apiClient.post('/chatgpt/', {
                // raw: false,
                prompt: inputValue,
                systemPrompt: systemPrompt,
                model: modelName
            })
                .then(response => {
                    setLoading(false);
                    console.log('response', response);
                    const {data} = response;
                    console.log(data);
                    // setModelName(data.model);
                    // console.log( data.choices );
                    setMessages(messages => [...messages, {
                        self: false,
                        text: data,
                        message: { content: data }
                    }]);

                    return;
                    // // loop through data.choices
                    // for (let i = 0; i < data.choices.length; i++) {
                    //     setMessages(messages => [...messages, {
                    //         text: data.choices[i].message.content,
                    //         self: false,
                    //         message: data.choices[i].message
                    //     }]);
                    // }
                    // const usage = data.usage;
                    // if (usage) {
                    //     const {completion_tokens, prompt_tokens, total_tokens} = usage;
                    //     const usageMessage = `prompt: ${prompt_tokens} + completion: ${completion_tokens} = ${total_tokens}`;
                    //     setMessages(messages => [...messages, {usage: usageMessage}]);
                    // }
                    // for()
                    // setMessages([...messages, { text: data.choices[0].text, self: false }]);
                })
                .catch(error => {
                    setLoading(false);
                });
        }
    };
    const chatWindowRef = useRef();
    const scrollToBottom = () => {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box
            height="100%"

            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            // width:'100%'
            flex="1"
            flexGrow="1"
            // minHeight="100vh"
            // bgcolor="#eeeeee"
        >
            <Stack spacing={1} margin={1} sx={{height: '100%', width: '100%'}}>

                {/*
                    <Grid container spacing={2} sx={{ width: '100%' }}>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                    */}

                {/*
                <Stack direction="row" spacing={2} sx={{width: '100%'}}>
                    <div>{modelName}</div>
                </Stack>
                */}

                <ChatWindow ref={chatWindowRef}>
                    {messages.map((message, index) => {
                        console.log('message', message);
                        if (message.usage) {
                            return (
                                <Usage key={index}>
                                    {message.usage}
                                </Usage>
                            );
                        } else {
                            return (
                                <Message key={index} self={message.self} text={message.text}/>
                            );
                        }
                    })}

                    {loading && <Message self={false}>
                        <div className={'text-gray-500 flex align-center items-center gap-4'}>
                        <CircularProgress color="inherit"/>
                        thinking...
                        </div>
                    </Message>}

                </ChatWindow>

                <form onSubmit={handleSubmit}>
                    <Box display="flex" alignItems="center" padding="1rem">
                        <TextField
                            sx={{backgroundColor: '#ffffff'}}
                            fullWidth
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            variant="outlined"
                            placeholder="Type your message..."
                        />
                        <Button type="submit" color="primary" variant="contained" style={{marginLeft: '1rem'}}>
                            Send
                        </Button>
                    </Box>
                </form>
            </Stack>
        </Box>
    );
};

export default ChatStream;
