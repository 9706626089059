import React, { useEffect, useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import AccountsTable from "./AccountsTable";
import { useDevTool } from "../../context/DevTool";
import { PageContainer } from "../../Home/PageContainer";
import { Grid, Box, Typography, Container, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import apiClient from "../../context/APIClient";
import { GRADE_LEVEL_CHOICES } from "../../Studio/Playlists/constants";
import DropdownFilter from "./DropdownFilter";
import { account_variants } from "../../Manage/CreateAccount/variants";

const S = {
    Container: styled.div`
        position: relative;
        outline: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        padding: 24px;
        //background-color: #EAEEF3;
        user-select: none;
    `
};

const getKey = (pageIndex, previousPageData) => {
    console.log('page ', pageIndex, previousPageData);
    if (previousPageData && !previousPageData.next)
        return null // reached the end
    return `/api/accounts/?page=${pageIndex + 1}&page_size=10`  // SWR key
}


function appendQueryParams(baseURL, params) {
    // const url = new URL(baseURL);
    // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    // return url.toString();
    const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    return `${baseURL}?${queryString}`;
}


const AccountsContainer = (props) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const tableRef = React.createRef();

    // const { data, error } = useSWR(`/api/accounts/?page=${page + 1}&page_size=${pageSize}`);
    // const { data, size, setSize } = useSWRInfinite(getKey)
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [accountMap, setAccountMap] = useState({});
    const [accounts, setAccounts] = useState([]);


    React.useEffect(() => {

        const downloadAccounts = async () => {
            let URI = '/api/accounts/';

            let allAccounts = {};

            // construct the query parameters for the API call
            // const params = {
            //     ...(dateObj && { year: dateObj.year(), month: dateObj.month() }),
            //     ...(user_id && { user_id }),
            //     ...(archive_term && { archive_term }),
            //     ...(videoViews && { video_views: videoViews }),
            //     source: 'ActivityProvider'
            // };

            const params = {
                page_size: 100
            };
            URI = appendQueryParams(URI, params);

            while (URI)
            {
                // get the activity
                try {
                    console.log('fetching activity', URI);
                    const response = await apiClient.get(URI);
                    const { data } = response;
                    const activity = data.results;

                    setTotalAccounts(data.count);

                    // allAccounts = [...allAccounts, ...activity];
                    setAccountMap((prevMap) => {
                        const newMap = { ...prevMap };
                        activity.forEach((account) => {
                            newMap[account.id] = account;
                        });
                        return newMap;
                    });

                    // // add the accounts to the setAccounts but don't duplicate
                    // setAccounts((prevAccounts) => {
                    //     const newAccounts = [...prevAccounts, ...activity];
                    //     const uniqueAccounts = new Set(newAccounts);
                    //     return [...uniqueAccounts];
                    // });


                    // dispatch({ type: 'LOAD_ACTIVITY', activity, user_id });

                    // Update the URI to the next page URL, or null if there are no more pages
                    URI = data.next;
                    if (URI) {
                        // Remove the domain and port, since you are making the request from the same origin.
                        const nextUrl = new URL(URI);
                        URI = nextUrl.pathname + nextUrl.search;
                    }
                } catch (error) {
                    console.log('error downloading activity', error);
                    throw error;
                }
            }
        }

        downloadAccounts()
            .then((accounts) => {

            })
            .catch((error) => {
                console.log('error downloading accounts', error);
            });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (accountMap) {
            const accounts = Object.values(accountMap);
            setAccounts(accounts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountMap]);

    useDevTool('Accounts', { accountMap, accounts });
    // console.log(allAccounts);


    // React.useEffect(() => {
    //     // if (!data)
    //     //     return;
    //     const { results, count, nest, previous } = data.data;
    //     console.log('effect results', results);
    //     if (rows.length === 0) {
    //         if (count) {
    //             console.log("Initializing rows", count);
    //             const newRows = Array.apply(null, Array(count)).map(function () {
    //                 return {};
    //             });
    //             newRows.splice(page * pageSize, results.length, ...results);
    //             console.log(newRows);
    //             setRows(newRows);
    //         }
    //     } else {
    //         setRows((r) => {
    //             r.splice(page * pageSize, results.length, ...results);
    //             console.log(tableRef);
    //             tableRef?.current?.onQueryChange()
    //             return r;
    //         })
    //     }
    // }, [data]);


    // const rows = useMemo(() => {
    //     if( !data )
    //         return [];
    //     return data.reduce((all, item) => {
    //         return [...all, ...item.results]
    //     }, [])
    // }, [data]);
    // console.log(rows);

    // const [data2, setData2] = useState([]);
    // useEffect(() => {
    //     const response = apiClient.get('/api/accounts/', { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    //         .then(response => {
    //             console.log(response);
    //             const { data } = response;
    //             console.log(data);
    //             setData2(data.results);
    //         });
    // }, []);

    // console.log('page size', pageSize);
    // console.log('page', page);

    const filterType = [
        [1, 'Teacher'],
        [10, 'Parent'],
    ]
    const filterVariants = account_variants.map((variant) => {
        return [variant.id, variant.label];
    });

    const [selectedFilterType, setSelectedFilterType] = useState(null);
    const [selectedFilterVariant, setSelectedFilterVariant] = useState(null);

    let filteredAccounts = accounts;

    if( selectedFilterType ) {
        filteredAccounts = accounts.filter((account) => {
            return account.account_type == selectedFilterType;
        });
    }

    if( selectedFilterVariant ) {
        filteredAccounts = filteredAccounts.filter((account) => {
            return account.account_variant == selectedFilterVariant;
        });
    }

    return (
        <PageContainer
            maxWidth={false}
            actions={[]}
            // rootTitle={'Admin'}
            // rootPath={'/admin'}
            paths={[
                { href: '/', title: 'Home' },
                { href: '/admin/', title: 'Admin' },
                // { href: '/admin/create-account/', title: 'Accounts' },
                // ...breadcrumbs
            ]}
            title="Account List"
        >

            <Container sx={{ padding: '2em' }} maxWidth={false}>
                {/*
                <Grid item xs={12}>
                    <div style={{ width: '100%', background: 'red' }}>
                        box
                    </div>
                </Grid>
                */}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                }}
                            >
                                Create Account
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid container xs={12} spacing={2}>

                        <Grid item xs={1}>
                            <DropdownFilter
                                label={'Account Type'}
                                items={filterType}
                                onChange={setSelectedFilterType}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <DropdownFilter
                                label={'Account Variant'}
                                items={filterVariants}
                                onChange={setSelectedFilterVariant}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <AccountsTable
                            tableRef={tableRef}
                            total={totalAccounts ?? 0}
                            rows={filteredAccounts ?? []}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={pageSize}
                            setRowsPerPage={setPageSize}
                        />
                    </Grid>
                </Grid>
            </Container>
        </PageContainer>
    )

};


export default AccountsContainer;
