import React from 'react';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";

const EditorContainer = ({children, rightPanel = null}) => {
    return (
        <Grid rowSpacing={1} columnSpacing={1} >
            <Grid
                item
                xs={12}
                // className={'bg-[red]'}
                // xs={rightPanel ? 9 : 12}
                // md={rightPanel ? 10 : 12}
            >
                <Paper>
                    {children}
                </Paper>
            </Grid>
            {/*
            {rightPanel && <Grid item xs={3} md={2}>
                <Stack spacing={2}>
                    {rightPanel}
                </Stack>
            </Grid>}
            */}
        </Grid>
    );
};

export default EditorContainer;
