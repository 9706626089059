import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {Clear} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

export const SearchField = ({value, onSearch}) => {
    return (
        <TextField
            label="Search"
            variant="outlined"
            value={value}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
                endAdornment: value && (
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={() => onSearch("")}>
                            <Clear fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
                style: {backgroundColor: 'white'},
            }}
        />
    );
};
