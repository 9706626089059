

export function parseQuestionBlock(text) {
    const lines = text.trim().split(/\r?\n/);

    let questionLines = [];
    let choices = [];
    let choiceRegex = /^[A-D][.)]\s*(.+)$/;
    let questionRegex = /^(?:\d+\.\s*)?(?:Question:\s*)?(.*)$/;
    let answerRegex = /^Answer:\s*([A-D])$/;
    let foundBlank = false;
    let answer = null;

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i].trim();

        if (!line) {
            foundBlank = true; // Detect blank line(s) separating question and choices
            continue;
        }

        if (!foundBlank) {
            let match = line.match(questionRegex);
            if (match) {
                questionLines.push(match[1]); // Collect multi-line question
            }
        } else if (choiceRegex.test(line)) {
            let match = line.match(choiceRegex);
            choices.push(match[1]);
        } else if (answerRegex.test(line)) {
            let match = line.match(answerRegex);
            answer = ["A", "B", "C", "D"].indexOf(match[1]);
        } else {
            return false; // If format breaks, return false
        }
    }

    let question = questionLines.join(' ').trim();

    if (!question || choices.length < 1 || choices.length > 4) {
        return false;
    }

    return { question, choices, answer };
}


// Example usage:
// const text = `Question: What is the perimeter of a shape?
// This can be defined as the boundary length.
//
// A. The distance around the shape.
// B) The amount of space inside the shape.
// C. How tall the shape is.
// D) The length of the longest side.`;
//
// console.log(parseQuestionBlock(text));

