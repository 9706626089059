import React from 'react';
// import { TreeRenderProps } from 'react-complex-tree';
import { Button } from "@mui/material";
import cx from "classnames";
//     <Icon
//         icon="chevron-right"
// className={cx(
//         Classes.TREE_NODE_CARET,
//     props.context.isExpanded
//         ? Classes.TREE_NODE_CARET_OPEN
//         : Classes.TREE_NODE_CARET_CLOSED
// )}
// {...(props.context.arrowProps as any)}
// />
const Classes = {
    TREE_NODE_LABEL: 'TREE_NODE_LABEL',
    TREE_NODE_SECONDARY_LABEL: 'TREE_NODE_SECONDARY_LABEL',
};

export const libraryRenderers = {

     renderTreeContainer: ({ children, containerProps }) => {
        console.log('renderTreeContainer', containerProps);
        return (
            <div
                {...containerProps}
                className={'py-6 px-2 bg-[transparent]'}
                // className={'py-10 px-2 bg-[gray]'}
                // className={'border-[red] border-2 m-0 p-0 bg-[yellow] gap-10'}
                // className={'bg-[#f0f0f0] p-0 m-0 border-[#666] border-8'}
                // className='bg-[white] flex flex-col gap-4'
            >
                {children}
            </div>
        );
    },

    renderItemsContainer: props => {
        const { children, containerProps } = props;
        // console.log('render items container', props);
        // console.log('RENDER CONTAINER:', props.parentId, props.containerProps.role, props);
        // console.log(props.containerProps);
        // const isSelected = props.item?.id === selectedGroup;
        // console.log(props.containerProps.role);

        if (props.info.treeId === 'lesson-palette') {
            return (
                <div
                    {...containerProps}
                    className={'grid grid-cols-3 gap-1 bg-[white] select-none'}
                >
                    {children}
                </div>
            );
        }
        const depth = 0;
        return (
            <div
                {...containerProps}
                // className={'border-[red] bg-[yellow] border-2 m-2 p-2 max-w-[400px] flex flex-col gap-10 select-none'}
                className={cx('flex flex-col gap-1 select-none', {
                    'border-[green] bg-[orange] border-2': depth === 1,
                    // [`border-[red] bg-[yellow] border-2`]: true,
                    // 'border-2 m-2 p-2': false
                })}
            >
                {children}
            </div>
        );
    },


    renderItem: props => (<div></div>),
    renderItemArrow: props => (<div></div>),
    renderItemTitle: ({ title, context, info }) => {
        return null;
    },
    renderDragBetweenLine: ({ draggingPosition, lineProps }) => (<div
            {...lineProps}
            style={{
                position: 'absolute',
                right: '0',
                top: draggingPosition.targetType === 'between-items' && draggingPosition.linePosition === 'top' ? '0px' : draggingPosition.targetType === 'between-items' && draggingPosition.linePosition === 'bottom' ? '-4px' : '-2px',
                left: `${draggingPosition.depth * 23}px`,
                height: '4px',
                // backgroundColor: Colors.BLUE3,
            }}
        />),
    renderRenameInput: props => (
        <form {...props.formProps} style={{ display: 'contents' }}>
            <span className={Classes.TREE_NODE_LABEL}>
            <input
                {...props.inputProps}
                ref={props.inputRef}
                className="rct-tree-item-renaming-input"
            />
            </span>
                <span className={Classes.TREE_NODE_SECONDARY_LABEL}>
                <Button
                    icon="tick"
                    {...(props.submitButtonProps)}
                    type="submit"
                    minimal
                    small
                />
                </span>
        </form>
    ),

    renderSearchInput: props => {
        const { ref, ...inputProps } = { ...props.inputProps };
        return (
            <div className={cx('rct-tree-search-input-container')}>
                {/*
                <InputGroup
                    intputRef={ref}
                    {...(inputProps as any)}
                    autoFocus
                    placeholder="Search..."
                />
                */}
            </div>
        );
    },

    renderDepthOffset: 1,
}

export default libraryRenderers;
