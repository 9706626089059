import React, {useCallback} from 'react';
import { Grid, Stack, Typography } from "@mui/material";
import { useDevTool } from "../../../context/DevTool";
import apiClient from "../../../context/APIClient";
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION } from "../../Quizzes/QuizEditor/constants";
import Panel from "../../../Components/Dashboard/Panel";
import { addStatusRow } from "../../Quizzes/QuizEditor/Tabs/HealthCheck";
import HealthCheckPanel from "../../Components/HealthCheck";

const VideoHealthCheck = ({ video }) => {
    const [checks, setChecks] = React.useState([]);
    const [isValid, setIsValid] = React.useState(video?.healthcheck_valid);
    useDevTool('VideoHealthCheck', checks);

    const performChecks = useCallback((force = false) => {
        setIsValid(null);
        apiClient.get(video ? `/api/video/${video.id}/check/?force=true` : null)
            .then(response => {
                console.log('response', response);
                const { valid, checks } = response.data;
                setIsValid(valid);
                setChecks(checks);
            })
            .catch(error => {
                console.log('error', error);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HealthCheckPanel
            isValid={isValid}
            checks={checks}
            performChecks={performChecks}
        />
    );

};


export default VideoHealthCheck;
