import React, {useMemo, useRef, useState} from 'react';
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack
} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank, Visibility, VisibilityOff} from "@material-ui/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import VolumeUpSharpIcon from '@mui/icons-material/VolumeUpSharp';
import SettingsIcon from '@mui/icons-material/Settings';

// import AddIcon from "@material-ui/icons/Add";
// import { QuestionChoice } from "./ChoiceEditor/QuestionChoice";
// import OutlineLabel from "../OutlineLabel";
// import ChoiceSettingsDialog from "./ChoiceEditor/ChoiceSettingsDialog";
import {QUESTION_TYPE_CHOICES, QUIZ_LEVEL_CHOICES} from "../constants";
import {QuestionEditorToolbar} from "../QuestionEditorToolbar";
import {useDevTool} from "../../../../context/DevTool";
import QuestionChoiceList from "./QuestionChoiceList";
import QuestionImage from "../QuestionImage";
import RichText from "../../../../Components/RichText/RichText";

import QuestionSettingsDialog from "../QuestionSettingsDialog";
// import TextEditorWYSIWYG from "../TextEditorWYSIWYG"; // ?? red
import {parseQuestionBlock} from "./ParseQuestionBlock";

QuestionEditorToolbar.propTypes = {onChange: PropTypes.func};

function CheckboxInField({checked, onChange, label}) {
    return (
        <FormControl variant="outlined" style={{width: '300px'}}>
            <InputLabel htmlFor="outlined-adornment-checkbox">{label}</InputLabel>
            <OutlinedInput
                label={label}
                id="outlined-adornment-checkbox"
                readOnly
                startAdornment={
                    <div tw='flex flex-row gap-2 items-center'>
                        <IconButton
                            edge="start"
                            onClick={() => onChange(!checked)}
                            aria-label="toggle checkbox"
                        >
                            {checked ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                        </IconButton>
                    </div>
                }
                labelWidth={40}
            />
        </FormControl>
    );
}


const QuestionEditor = ({quizId, quiz, questionId, question, updateQuestion, updateChoice}) => {
    // console.log('QuestionEditor', question);
    // console.log('question id', quizId, quiz, question);
    useDevTool('QuestionEditor', {quizId, quiz, questionId, question});
    // console.log('QuestionEditor', { quizId, quiz, questionId, question });
    // quiz={quiz}
    // question={question}
    // updateQuestion={updateQuestion}
    // dirty={dirty}
    // console.log(question?.choices)
    const navigate = useNavigate();

    const handleAddChoiceBlock = (choiceBlock) => {
        // console.log('handleAddChoiceBlock', choiceBlock);

        // add or update existing choices
        const existingChoices = question?.choices || [];
        const choices = choiceBlock.map((choice, index) => {
            let existing = {};
            let choiceId = uuidv4();
            let newChoice = true;
            let correct = false;

            if( index < existingChoices.length ) {
                newChoice = false;
                existing = existingChoices[index];
                choiceId = existing.id;
                correct = existing.correct;
            }

            return {
                ...existing,
                new: newChoice,
                id: choiceId,
                choice_index: index,
                choice_text: choice,
                correct: correct,
                content_image: false
            };
        });
        updateQuestion({...question, choices});
    }

    const handleChangeType = (event) => {
        updateQuestion({...question, type: parseInt(event.target.value)});
    }

    const handleChangeLevel = (event) => {
        updateQuestion({id: question?.id, level: parseInt(event.target.value)});
    }

    const handleChangeIndex = (event) => {
        updateQuestion({id: question?.id, question_index: parseInt(event.target.value) - 1});
    }

    const handleChangeText = (event) => {
        console.log('handleChangeText', event.target.value);
        // check if the user is pasting an entire question block
        const block = parseQuestionBlock(event.target.value);
        if( block ) {
            console.log("Block entered", block);
            updateQuestion({
                id: question?.id,
                question_text: block['question']
            });

            for (let i = 0; i < block['choices'].length; i++) {
                updateChoice({
                    id: question?.choices?.[i].id ?? uuidv4(),
                    choice_text: block.choices?.[i],
                    correct: block.answer === i
                });
            }
            return;
        }
        updateQuestion({id: question?.id, question_text: event.target.value});
    }

    const handleChangeInstructions = (event) => {
        updateQuestion({id: question?.id, instruction_text: event.target.value});
    }

    const handleChangeImageURL = (event) => {
        updateQuestion({...question, image_url: event.target.value});
    }

    const handleChangeQuestionValue = (key) => (event) => {
        updateQuestion({...question, [key]: event.target.value});
    }

    const handleFreezeOrder = (freeze) => {
        question?.choices.forEach(choice => updateChoice({id: choice.id, last_position: freeze}));
    }

    const handleChangeChoiceValue = (event, choiceId) => {
        // console.log(event.target.value, choiceId);
        const choices = question?.choices.map((choice) => {
            if (choice.id === choiceId) {
                return {...choice, choice_text: event.target.value};
            }
            return choice;
        });
        updateQuestion({...question, choices});
    }

    const handleDeleteChoice = (choiceId) => {
        const choices = question?.choices.filter((choice) => choice.id !== choiceId);
        updateQuestion({...question, choices});
    }
    const handleAddTextChoice = () => {
        const choices = question?.choices.concat({
            new: true,
            id: uuidv4(),
            choice_index: question?.choices.length + 1,
            choice_text: '',
            correct: false,
            content_image: false
        });
        updateQuestion({...question, choices});
    }
    const handleAddImageChoice = () => {
        const choices = question?.choices.concat({
            new: true,
            id: uuidv4(),
            choice_index: question?.choices.length + 1,
            choice_text: '',
            correct: false,
            content_image: true
        });
        updateQuestion({...question, choices});
    }

    const handleAddMathChoice = () => {
        const choices = question?.choices.concat({
            new: true,
            id: uuidv4(),
            choice_index: question?.choices.length + 1,
            choice_text: '<math></math>',
            correct: false,
            content_math: true,
            content_image: false
        });

        updateQuestion({...question, choices});
    }


    const handleSetImage = (choiceId) => {
        // toggle the 'content_image' flag on the choice
        const choices = question?.choices.map((choice) => {
            if (choice.id === choiceId) {
                return {...choice, content_image: !choice.content_image};
            }
            return choice;
        });
        updateQuestion({...question, choices});
    }
    const handleAudioSettings = (choice) => {

    }
    const handleOpenSettings = (choice) => {

    }

    // const updateChoice = (choice) => {
    //     const choices = question?.choices.map((c) => {
    //         if (c.id === choice.id) {
    //             return choice;
    //         }
    //         return c;
    //     });
    //     updateQuestion({ ...question, choices });
    // }

    const deleteChoice = (choiceId) => {
        const choices = question?.choices.filter((choice) => choice.id !== choiceId);
        updateQuestion({...question, choices});
    }

    const actions = useMemo(() => {
        const actions = [
            {
                id: 'image',
                icon: <ImageIcon/>,
                name: 'Image',
            },
            /*
            {
                id: 'play-audio',
                icon: <TuneIcon />,
                name: 'Audio',
            },
            */
            {
                id: 'audio-settings',
                icon: <VolumeUpSharpIcon/>,
                name: 'Tune',
                // color: 'success',
            },
            {
                id: 'settings',
                icon: <SettingsIcon/>,
                name: 'Settings',
            },
            {
                id: 'delete',
                icon: <DeleteIcon/>,
                name: 'Delete',
            }
        ];
        // {choice.content_image ? 'success' : 'default'}
        return actions;
    }, []);

    function handleClickAction(choice, action) {
        // console.log(choice, action);
        if (action.id === 'image') {
            handleSetImage(choice.id);
        } else if (action.id === 'play-audio') {
            handlePlayAudio(choice);
        } else if (action.id === 'audio-settings') {
            handleAudioSettings(choice);
        } else if (action.id === 'settings') {
            handleOpenSettings(choice);
        } else if (action.id === 'delete') {
            handleDeleteChoice(choice.id);
        }
    }

    const getActionColor = (choice, action_id) => {
        if (action_id === 'play-audio') {
            // return 'success';
        }
        if (action_id === 'audio-settings') {
            // return 'success';
        }
        if (action_id === 'settings') {
            // return 'info';
        }
        if (action_id === 'delete') {
            // return 'warning';
        }
        if (action_id === 'image') {
            return choice.content_image ? 'success' : 'default';
        }
        // 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
        return 'default'
    }

    // if (!question) {
    //     // this question is invalid, redirect to the first question
    //     console.log('invalid question', question, quiz?.questions?.length);
    //     if (quiz?.questions?.length > 0 && quizId) {
    //         console.log('redirecting to first question', quizId, quiz.questions[0].id);
    //         navigate(`/quiz/${quizId}/question/${quiz.questions[0].id}`);
    //         // return <Redirect to={`/quiz/${quiz.id}/question/${quiz.questions[0].id}`} />;
    //     }
    //     // return <div>Question not found</div>;
    // }
    const [showImage, setShowImage] = useState(false);

    const onChangeLayout = (event) => {
        updateQuestion({...question, layout: event.target.value});
    };

    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleConfirmSettings = () => {
        setSettingsOpen(false);
    }


    const audioElement = useRef();

    function handlePlayAudio() {
        if (!question?.audio) {
            return;
        }

        if (audioElement.current) {
            // audioElement.current.pause();
            audioElement.current.play();
            audioElement.current.currentTime = 0;
        }

        // audioElement.current = new Audio(choice.audio);
    }

    return (
        <Grid container spacing={2}>

            {/*
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            */}

            <Grid item xs={12}>
                <div className={'-bg-red-100 flex flex-row'}>
                    <Grid container spacing={2}>
                        {/* Level & Question Number */}
                        <Grid item xs={12}>
                            <Stack spacing={2} direction={'row'}>
                                <FormControl fullWidth>
                                    <InputLabel id="question-level-select-label">Level</InputLabel>
                                    <Select
                                        id="question-level"
                                        label="Level"
                                        value={String(question?.level)}
                                        onChange={handleChangeLevel}
                                    >
                                        {QUIZ_LEVEL_CHOICES.map((item) => (
                                            <MenuItem key={item[1]} value={String(item[0])}>{item[1]}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="question-number-select-label">
                                        Question Number
                                    </InputLabel>
                                    <OutlinedInput
                                        id="question-number"
                                        readOnly
                                        value={(question?.question_index ?? 0) + 1}
                                        label="Question Number"
                                        onChange={handleChangeIndex}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="question-type-select-label">Type</InputLabel>
                                    <Select
                                        id="question-type"
                                        value={String(question?.type)}
                                        label="Type"
                                        onChange={handleChangeType}
                                    >
                                        {QUESTION_TYPE_CHOICES.map((item) => {
                                            return (
                                                <MenuItem key={item[0]} value={String(item[0])}>{item[1]}</MenuItem>);
                                        })}
                                    </Select>
                                </FormControl>
                                <CheckboxInField label={"Warmup"} checked={question?.warmup} onChange={(value) => {
                                    updateQuestion({warmup: value})
                                }}/>
                                <CheckboxInField label={"Random Position"} checked={question?.random_position}
                                                 onChange={(value) => {
                                                     updateQuestion({random_position: value})
                                                 }}/>
                                <Button variant="outlined" onClick={() => setSettingsOpen(true)}>
                                    <SettingsIcon/>
                                </Button>
                            </Stack>
                        </Grid>
                        {/* Question Contents */}
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <audio
                                    ref={audioElement}
                                    src={question?.audio}
                                    className='audio-player-node'
                                    autoPlay={false}
                                />


                                {/*<QuestionContentEditor question={question} updateQuestion={updateQuestion} />*/}


                                <FormControl fullWidth sx={{mb: 0}}>
                                    <InputLabel id="question-text-input-label">
                                        Question Text
                                    </InputLabel>

                                    <OutlinedInput
                                        id="question-text-input"
                                        label="Question Text"
                                        name='question_text'
                                        multiline
                                        minRows={2}
                                        value={question?.question_text ?? ''}
                                        onChange={handleChangeText}
                                        endAdornment={(
                                            <InputAdornment position="start">
                                                <Stack spacing={0} direction={'row'}>

                                                    <IconButton disabled={!question?.audio} onClick={handlePlayAudio}>
                                                        <VolumeUpSharpIcon/>
                                                    </IconButton>

                                                    <IconButton onClick={() => setSettingsOpen(true)}>
                                                        <SettingsIcon htmlColor='#888888'/>
                                                    </IconButton>

                                                    {!question?.image_url &&
                                                        <IconButton onClick={() => setShowImage(!showImage)}>
                                                            <ImageIcon htmlColor='#888888'/>
                                                        </IconButton>}

                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowImage(!showImage)}
                                                        onMouseDown={(event) => event.preventDefault()}
                                                        edge="start"
                                                    >
                                                        {showImage ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>

                                                </Stack>
                                            </InputAdornment>
                                        )}
                                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </FormControl>
                                {(showImage || question?.image_url) &&
                                    <QuestionImage question={question} updateQuestion={updateQuestion}
                                                   showURL={!showImage}/>}

                                <FormControl fullWidth sx={{mb: 0}}>
                                    <InputLabel id="question-instruction-input-label">
                                        Instruction Override
                                    </InputLabel>

                                    <OutlinedInput
                                        id="question-instruction-input"
                                        label="Question Override"
                                        name='instruction_text'
                                        value={question?.instruction_text ?? ''}
                                        onChange={handleChangeInstructions}
                                    />
                                </FormControl>

                                {/*
                                <div className={'border-gray-200 border- border-solid'}>
                                    <TextEditorWYSIWYG
                                        className={'bg-white p-2'}
                                        initialValue={question ? question?.question_text ?? 'this is empty' : "question is null"}
                                        setValue={(value) => updateQuestion({ ...question, question_text: value })}
                                    />
                                </div>
                                */}

                                <div className={'bg-gray-200 p-0.5'}>
                                    <h3 className={'p-1'}>Preview:</h3>
                                    <RichText className={'bg-white p-2'} text={question?.question_text ?? ''}/>
                                </div>

                            </Stack>
                        </Grid>
                    </Grid>

                    {/*
                            <Stack spacing={2} direction={'column'} sx={{ marginLeft: 1 }}>
                                <IconButton size='sm' fullWidth={true} onClick={() => setShowImage(!showImage)}>
                                    <SettingsIcon htmlColor='#888888' />
                                </IconButton>
                                <IconButton>
                                    <ImageIcon htmlColor='#888888' />
                                </IconButton>
                            </Stack>
*/}
                </div>

                {/*                <div>{question?.id}</div>
                <div>{question?.level}</div>
                <div>{question?.index}</div>*/}
                <Paper variant={'outlined'} sx={{padding: 2, marginTop: 2}}>
                    <QuestionChoiceList
                        question={question}
                        handleAddTextChoice={handleAddTextChoice}
                        handleAddImageChoice={handleAddImageChoice}
                        handleAddMathChoice={handleAddMathChoice}
                        handleChangeChoiceValue={handleChangeChoiceValue}
                        handleFreezeOrder={handleFreezeOrder}

                        deleteChoice={deleteChoice}
                        updateChoice={updateChoice}
                        addChoiceBlock={handleAddChoiceBlock}
                        onChangeLayout={onChangeLayout}
                    />
                </Paper>
            </Grid>

            <QuestionSettingsDialog
                key={question?.id}
                choice={{id: '1'}}
                question={question}
                open={settingsOpen && question}
                setOpen={setSettingsOpen}
                updateQuestion={updateQuestion}
                onConfirm={handleConfirmSettings}
                onDelete={handleDeleteChoice}
            />

        </Grid>
    );
};


export default QuestionEditor;
