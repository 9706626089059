import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Paper, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Grid, Stack } from "@mui/material";
import BitmovinPlayer from "../../../Components/Bitmovin";
import {createSourceConfig, hasSourceConfig} from "../../../Components/Bitmovin/BitmovinPlayer";
import VideoPlayer from "../VideoPlayer";
import {VideoSpecs} from "./VideoInfo/VideoInfoPlayer";



const VideoStreams = ({ video, updateVideo }) => {
    return (
        <Stack>
            <h1>Video Streams</h1>
            <div className={'border border-gray-300 rounded-md py-4'}>
                <div className={'text-center text-2xl text-gray-500'}>
                    No video available
                </div>
            </div>
        </Stack>
    );
};


export default VideoStreams;
