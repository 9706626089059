import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Button, Grid, Stack, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import InputText from "../Quizzes/QuizEditor/FormControls";
import apiClient from "../../context/APIClient";
import AddVideoDialog from '../Playlists/Dialogs/AddVideoDialog';
import AddQuizDialog from '../StudioContainer/Dialogs/AddQuizDialog';
import { useParams } from "react-router-dom";
import { useStudio } from "../Context/StudioContext";
import DropboxChooser from '../Videos/DropboxChooser';
import { useNavigate } from 'react-router-dom';
import VideoInputField from './VideoInputField';
import QuizInputField from '../Videos/forms/QuizInputField';
import LessonGroupField from './LessonGroupField';
import DeleteLessonDialog from '../StudioContainer/Dialogs/DeleteLessonDialog';
import { useGetAPI } from "../../context/api";
import { QuizzesDropdown } from "../Quizzes/QuizEditor/QuestionEditorToolbar";
import { PageContainer } from "../../Home/PageContainer";

export const LessonEditorAppContainer = (props) => {
    const { lessonId } = useParams();
    const { lessons } = useStudio();
    const [lesson, setLesson] = React.useState({});

    useEffect(() => {
        if (lessons && lessonId)
            setLesson(lessons.find(l => l.id === lessonId));
    }, [lessons, lessonId]);

    // const updateLesson = useCallback((update) => {
    //     setLesson(value => ({ ...value, ...update }));
    // }, []);

    return (
        <LessonEditor
            key={lesson.id}
            lessonId={lessonId}
            esson={lesson}
            // updateLesson={updateLesson}
            {...props}
        />
    )
};

const LessonEditor = ({ lesson: initialLesson, lessonGroup, treeItem }) => {
    const studio = useStudio();
    const navigate = useNavigate();
    const { mutateVideos, mutateLessons, mutateCourses } = studio;
    const [openAddVideo, setOpenAddVideo] = useState(false);
    const [openAddQuiz, setOpenAddQuiz] = useState(false);
    const [openDeleteLesson, setOpenDeleteLesson] = useState(false)
    const [lesson, setLesson] = React.useState(initialLesson || {});

    // const { data } = useGetAPI(`/api/lessons/${lessonId}/`);
    // if (lessonId) lesson = lessons.find(l => l.id === lessonId)
    const updateLesson = useCallback((update) => {
        setLesson(value => (
            { ...value, ...update }
        ));
    }, []);

    const handleChangeValue = (event) => {
        let { name, value } = event.target;
        // console.log('handleChangeValue', name, value);
        updateLesson({ [name]: value });
    };

    const handleCreateQuiz = () => {
        const payload = { quiz_type: 'standard', lesson_id: lesson.id }
        apiClient.post('/api/quiz/', payload)
            .then((response) => {
                console.log(response);
                const { data } = response;
                navigate(`/quiz/${data.id}`)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSuccess = (files) => {
        console.log('handleSuccess', files);
        const file = files[0];
        const payload = { dropbox_url: file.link, lesson_id: lesson.id }
        apiClient.post('/api/video/', payload)
            .then((response) => {
                console.log(response);
                const { data } = response;
                // load the video
                mutateVideos()
                    .then(response => {
                        navigate(`/videos/${data.id}`)
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        // updateVideo({ video_url: file.link });
    }

    const updateQuiz = (data) => {
        apiClient.patch(`/api/admin/lessons/${lesson?.id}/`, data)
            .then((response) => {
                console.log(response);
                mutateVideos();
                mutateLessons();
                mutateCourses();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateVideo = (data) => {
        apiClient.patch(`/api/admin/lessons/${lesson?.id}/`, data)
            .then((response) => {
                console.log(response);
                mutateVideos();
                mutateLessons();
                mutateCourses();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const saveLesson = (data) => {
        apiClient.patch(`/api/admin/lessons/${lesson?.id}/`, data)
            .then((response) => {
                console.log(response);
                mutateVideos();
                mutateLessons();
                mutateCourses();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSaveAndContinue = () => {

    }

    const handleSave = () => {
        saveLesson(lesson);
    };

    return (
        <PageContainer
            // key={quizId}
            // title={data.title}
            // title={<QuizzesDropdown />}
            title={lesson?.title}
            paths={[
                { href: '/', title: 'Home' },
                { href: '/studio/', title: 'Studio' },
                { href: '/studio/lessons/', title: 'Lessons' }
            ]}
            maxWidth={'xl'}
        >
            <Grid container rowSpacing={0} columnSpacing={3}>
                <Grid item xs={12} md={8} lg={9} xl={9}>
                    <Card sx={{ m: 0 }}>
                        <CardHeader title='Lesson' />
                        <CardContent>
                            <Stack spacing={2}>
                                <InputText onChange={handleChangeValue} id='title' name='title' value={lesson?.title} label='Title' />
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-description">Description</InputLabel>
                                    <OutlinedInput
                                        multiline
                                        id="outlined-adornment-description"
                                        value={lesson?.description}
                                        rows={4}
                                        // onChange={(ev) => updateVideo({ description: ev.target.value })}
                                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Description"
                                    />
                                </FormControl>
                                <VideoInputField lessonId={lesson?.id} updateVideo={updateVideo} />
                                <QuizInputField quizId={lesson?.quiz_id} updateQuiz={updateQuiz} />
                                <LessonGroupField lessonId={lesson?.id} />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={3}>
                    <Card sx={{ m: 0 }}>
                        <CardContent>
                            <Stack spacing={2}>

                                <Button variant='outlined' size='small' color='success' onClick={handleSave} className='min-h-[36px]'>
                                    Save
                                </Button>

                                <Button variant='outlined' size='small' color='primary' onClick={handleSaveAndContinue} className='min-h-[36px]'>
                                    Save And Continue Editing
                                </Button>

                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    size='small'

                                >
                                    <DropboxChooser
                                        appKey="w7l7sbxwq4gyquq"
                                        success={handleSuccess}
                                        multiselect={false}
                                        extensions={['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mpg', '.mpeg', '.m4v', '.webm', '.mkv']}
                                        // linkType="preview"
                                        linkText="direct"
                                    >
                                        <button>Select a video from Dropbox</button>
                                    </DropboxChooser>
                                </Button>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={() => setOpenAddVideo(true)}
                                >
                                    Add Video
                                </Button>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={handleCreateQuiz}

                                >
                                    Create Quiz
                                </Button>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={() => setOpenAddQuiz(true)}

                                >
                                    Add Quiz
                                </Button>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={() => {
                                        setOpenDeleteLesson(true);
                                    }}
                                >
                                    Delete Lesson
                                </Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <AddVideoDialog open={openAddVideo} onClose={() => setOpenAddVideo(false)} lessonId={lesson?.id} lesson={true} />
                <AddQuizDialog open={openAddQuiz} onClose={() => setOpenAddQuiz(false)} lessonId={lesson?.id} />
                <DeleteLessonDialog open={openDeleteLesson} onClose={() => setOpenDeleteLesson(false)} lesson={lesson} lessonGroup={lessonGroup} />


            </Grid>
        </PageContainer>
    );
};

export default LessonEditor;
