import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import apiClient from "../../../context/APIClient";
import {useNavigate} from "react-router-dom";
import VideoUpload from "./VideoUpload";
import {useStudio} from "../../Context/StudioContext";
import Loading from "../../../Components/Loading/Loading";
import VolumeDropdown from "../../StudioContainer/VolumeDropdown";
import DropboxChooser from "../DropboxChooser";

const NewVideoDialog = ({open, onClose, initialVolume = null}) => {
    const {register, handleSubmit, trigger, formState: {errors}, control} = useForm();
    const [volume, setVolume] = React.useState(initialVolume);

    const handleClose = () => {
        onClose();
    }
    const navigate = useNavigate();
    const {mutateVideos, mutateLessons} = useStudio();
    const [loading, setLoading] = React.useState(false);
    const [dropboxFiles, setDropboxFiles] = React.useState([]);

    const onSubmit = data => {
        data.volume_id = volume;
        setLoading(true);
        // post the data to the server
        console.log(data);
        apiClient.post('/api/video/', data)
            .then((response) => {
                console.log(response);
                const {data} = response;
                mutateVideos((videos) => (
                    [...videos, data]
                ));
                // mutateLessons().then(() => {
                // }).catch((error) => {
                //     console.log(error);
                //     setLoading(false);
                // });
                mutateLessons();
                navigate(`/videos/${data.id}`);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };


    const handleUploadFromDropbox = (files) => {
        console.log('handleUploadFromDropbox', files);
        setDropboxFiles(files);
        // const file = files[0];
        // updateVideo({ video_url: file.link });
        // const payload = {
        //     files,
        //     upload_id: uploadId,
        //     video_id: video?.id
        // }
        // return apiClient.post('/api/video_upload_dropbox/', payload)
        //     .then(res => {
        //         console.log('response', res.data);
        //         console.log("mutating list");
        //         mutate(({ data }) => {
        //             console.log('video files', data);
        //             return (
        //                 [...data, {
        //                     id: res.data.id,
        //                     name: 'new',
        //                     status: 'pending',
        //                 }]
        //             )
        //         });
        //     })
        //     .catch(error => {
        //         console.error("Error uploading from dropbox:", error);
        //     });
    }


    // console.log(errors);
    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
            <Loading loading={loading}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Create New Video</DialogTitle>
                <DialogContent>

                    {/*
                    <DropboxChooser
                        appKey="w7l7sbxwq4gyquq"
                        success={handleUploadFromDropbox}
                        multiselect={false}
                        extensions={['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mpg', '.mpeg', '.m4v', '.webm', '.mkv']}
                        // linkType="preview"
                        linkText="direct"
                    >
                        <Button className={'w-full'} variant='outlined' size='small' color='primary'>
                            Select a video from Dropbox
                        </Button>
                        {dropboxFiles.map((file) => (
                            <div key={file.id}>
                                {file.name}
                            </div>
                        ))}
                    </DropboxChooser>

                    <VideoUpload/>
                    */}

                    <DialogContentText>
                        <Stack spacing={2} sx={{paddingTop: '10px'}}>
                            {/*
                            <Controller
                                name={"name"}
                                required={true}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                            */}
                            <TextField
                                autoFocus
                                fullWidth
                                // onChange={onChange}
                                // value={value}
                                label={"Name"}
                                {...register("title", {required: true})}
                            />

                            <VolumeDropdown
                                selectedVolume={volume}
                                onChangeVolume={setVolume}
                            />

                            {/*
                                )}
                            />
                            */}
                            {showError(errors.name)}


                            {/*
                            <FormControl fullWidth>
                                <InputLabel id="quiz-type-label">Type</InputLabel>
                                <Select
                                    id="quiz-type"
                                    labelId="quiz-type-label"
                                    label="Type"
                                    {...register("quiz_type", { required: true })}
                                    defaultValue={QUIZ_TYPE_STANDARD}
                                    // value={String(question?.level)}
                                    // onChange={handleChangeLevel}
                                >
                                    {QUIZ_TYPE_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <Controller
                                name={"quiz_type"}
                                required={true}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="quiz-type-label">Level</InputLabel>
                                        <Select
                                            id="quiz-type"
                                            labelId="quiz-type-label"
                                            label="Type"
                                            // value={String(question?.level)}
                                            // onChange={handleChangeLevel}
                                        >
                                            {QUIZ_TYPE_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            */}
                            {showError(errors.quiz_type)}

                            <Controller
                                name={"description"}
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <TextField
                                        variant='outlined'
                                        label='Description'
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            {showError(errors.description)}

                            {/*
                            <Controller
                                name={"notes"}
                                control={control}
                                required={false}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        label='Notes'
                                        variant='outlined'
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            */}

                        </Stack>

                        {/*<input defaultValue="Name" {...register("name")} />*/}
                    </DialogContentText>


                </DialogContent>

                <DialogActions>
                    <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewVideoDialog;
